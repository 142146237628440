import rightImg from "images/image.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import SectionHero2 from "./SectionHero2";
import AboutUsSection from "./AboutUsSection";
import SectionInfoBanner from "./SectionInfoBanner";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Car rental</title>
      </Helmet>

      <div className="container pt-16 lg:pt-28 space-y-16 ">
        <SectionHero
          rightImg={rightImg}
          heading="Welcome to Why Kings Car Rentals"
          btnText=""
          subHeading={globalJson?.about_us_para}
        />
      </div>
      <div className="container py-12 space-y-8 ">
        <p className="text-[#121212B2] text-lg">
          We started Premier AZ Car Rentals because we love hospitality and
          wanted to offer a better option after our own less-than-perfect rental
          experiences. Today, our mission is simple: to make your car rental
          experience easy, clean, and reliable. We’re honored to be a part of
          your journey and the memories you create in Arizona.
        </p>
        <p className="text-[#121212B2] text-lg">
          As we continue to expand our fleet, our values of reliability,
          cleanliness, and convenience remain at the heart of everything we do.
          We look forward to welcoming you and making your trip unforgettable.
        </p>
      </div>
      <SectionInfoBanner />
      {/* <SectionFounder /> */}
      <div className="relative py-16">
        {/* <BackgroundSection /> */}
        <SectionClientSay uniqueClassName="PageAbout_" />
      </div>
    </div>
  );
};

export default PageAbout;

import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`nc-SectionHero relative ${className} `}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left mt-24 lg:mt-0">
        <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div>
        <div className="w-screen max-w-full  space-y-5 lg:space-y-7">
          <p className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 ">
            <p className="text-primary-200 text-base">About Us</p>
            <span>
              Rent <span className="text-primary-200">The Best</span> For You
            </span>
          </p>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            <p className="text-base text-[#121212B2]">
              Welcome to Premier AZ Car Rentals, a family-owned business proudly
              serving the East Valley and beyond. Located just minutes from Mesa
              Gateway Airport, we’ve been making car rentals easy and reliable
              for travelers in Arizona for over a year and a half.
            </p>
            <br />
            <p className="text-base text-[#121212B2]">
              Premier AZ Car Rentals, a family-owned business proudly serving
              the East Valley and beyond. Located just minutes from Mesa Gateway
              Airport, we’ve been making car rentals easy and reliable for
              travelers in Arizona for over a year and a half.
            </p>
            <span></span>
          </span>
          <div className="bg-[#07BEB833] py-5 px-2 rounded-lg">
            <div className="flex flex-row gap-4  text-left">
              <div>
                <svg
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.1258 14.75V10.5508H23.2273V14.75H19.1258ZM9.36016 32.8164C12.6805 36.0716 16.6193 37.6992 21.1766 37.6992C25.7339 37.6992 29.6401 36.0716 32.8953 32.8164C36.2156 29.4961 37.8758 25.5573 37.8758 21C37.8758 16.4427 36.2156 12.5365 32.8953 9.28125C29.6401 5.96094 25.7339 4.30078 21.1766 4.30078C16.6193 4.30078 12.6805 5.96094 9.36016 9.28125C6.10495 12.5365 4.47734 16.4427 4.47734 21C4.47734 25.5573 6.10495 29.4961 9.36016 32.8164ZM6.43047 6.35156C10.532 2.25 15.4474 0.199219 21.1766 0.199219C26.9057 0.199219 31.7885 2.25 35.825 6.35156C39.9266 10.388 41.9773 15.2708 41.9773 21C41.9773 26.7292 39.9266 31.6445 35.825 35.7461C31.7885 39.7826 26.9057 41.8008 21.1766 41.8008C15.4474 41.8008 10.532 39.7826 6.43047 35.7461C2.39401 31.6445 0.375782 26.7292 0.375782 21C0.375782 15.2708 2.39401 10.388 6.43047 6.35156ZM19.1258 31.4492V18.9492H23.2273V31.4492H19.1258Z"
                    fill="#07BEB8"
                  />
                </svg>
              </div>
              <div>
                <span className="text-[#54595F] font-semibold">
                  Information
                </span>
                <br />
                <span className="text-[#54595F] !pointer-events-autotext-left">
                  Our fleet includes economy cars, SUVs, pickup trucks, electric
                  vehicles, and luxury sedans. We also offer convenient add-ons
                  like golf clubs, coolers, chairs, and car seats to make your
                  trip even more enjoyable.With over 150 five-star reviews, our
                  customers keep coming back for the exceptional service and
                  personalized experience we provide.
                </span>
              </div>
            </div>
          </div>
          <ButtonPrimary onClick={() => navigate("/about")}>
            Read More About Us
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
